export const IMAGE_BASE_URL = 'https://static.petdoc.co.kr/petdoc-official';

export const ANCHOR_IDS = {
	STORY: 'story',
	BUSINESSES: 'businesses',
	CONTACT: 'contact',
	HISTORY: 'history',
	VETERIANARIAN: 'veterianarian',
	VPETMILYLAB: 'vpetmilylab',
	PETDOCAPP: 'petdocapp',
	VPARTNERS: 'vpartners',
	FOURPAWS: 'fourpaws',
	VLAB: 'vlab',
	VAD: 'vad',
	VCARE: 'vcare',
};

export const INTERNAL_URLS = {
	ANNOUNCEMENT: '/announcement',
	ANNOUNCE_DETAIL: '/announcement/view',
	HOME: '/',
	STORY: `/#${ANCHOR_IDS.STORY}`,
	BUSINESSES: `/#${ANCHOR_IDS.BUSINESSES}`,
	CONTACT: `/#${ANCHOR_IDS.CONTACT}`,
	HISTORY: `/#${ANCHOR_IDS.HISTORY}`,
	VETERIANARIAN: `/#${ANCHOR_IDS.VETERIANARIAN}`,
	VPETMILYLAB: `/#${ANCHOR_IDS.VPETMILYLAB}`,
	PETDOCAPP: `/#${ANCHOR_IDS.PETDOCAPP}`,
	VPARTNERS: `/#${ANCHOR_IDS.VPARTNERS}`,
	FOURPAWS: `/#${ANCHOR_IDS.FOURPAWS}`,
	VLAB: `/#${ANCHOR_IDS.VLAB}`,
	VAD: `/#${ANCHOR_IDS.VAD}`,
	VCARE: `/#${ANCHOR_IDS.VCARE}`,
	PRIVACY: `/policy/privacy`,
	SERVICE: `/policy/service`,
};

export const OUTER_URLS = {
	VLAB: 'https://www.vlab.kr/',
	VCARE: 'https://www.instagram.com/v__care/',
	VPARTNER: 'https://partner.petdoc.co.kr/',
	FOURPAWS: 'https://fourpaws.co.kr/',
	INSTAGRAM: 'https://www.instagram.com/petdoc_official/?hl=ko',
	YOUTUBE: 'https://www.youtube.com/channel/UCDJ5mikV_sfAvD8n_ZFiTvQ',
	RECRUIT: 'https://recruit.petdoc.co.kr',
	VETSHOP: 'https://xn--tl3bw1s9pl.com/',
	FOURPAWS_INSTAGRAM: 'https://www.instagram.com/fourpaws_official_/',
	FOURPAWS_YOUTUBE: 'https://www.youtube.com/channel/UC1l1VivE8P9th_CDXbRDkiQ'
};
