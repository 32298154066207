import React from 'react';
import styled from '@emotion/styled';
import { pxToRem } from '../utils/common';
import copyrightImg from '../assets/images/footer_copyright.svg';
import { DesktopFooterData, MobileFooterData } from '../data/footer';
import { BLACK, WHITE } from '../constants/colors';
import { Link } from 'gatsby';
import { INTERNAL_URLS } from '../constants/urls';
import { INNER_ELEMENT_WIDTH, WINDOW_MIN_WIDTH } from '../constants/layout';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../styles/layout';
import { css } from '@emotion/react';

const Footer = () => {
	return (
		<FooterWrapper>
			<FooterBox>
				<LogoImg src={copyrightImg} alt="logo" />
				<FooterInfoDesktopWrapper>
					{DesktopFooterData.map((data) => (
						<div key={data.id}>
							<FooterInfo>
								{data.info && data.info.map((data, index) => <FooterInfoText key={index}>{data}</FooterInfoText>)}
							</FooterInfo>
							<FooterInfo>{data.text}</FooterInfo>
						</div>
					))}
				</FooterInfoDesktopWrapper>
				<FooterInfoMobileWrapper>
					{MobileFooterData.map((data) => (
						<div key={data.id}>
							<FooterInfo>{data.text}</FooterInfo>
						</div>
					))}
				</FooterInfoMobileWrapper>
				<FooterLink to="https://petdoc.co.kr/announcement">공고</FooterLink>
			</FooterBox>
		</FooterWrapper>
	);
};

export default Footer;

const FooterWrapper = styled.footer`
	width: 100%;
	padding: ${pxToRem(40)} ${pxToRem(24)};
	background: ${BLACK};
	color: ${WHITE};
	font-weight: 400;
	font-size: ${pxToRem(13)};
	line-height: ${pxToRem(22)};

	${getDesktopStyle(css`
		padding: ${pxToRem(50)} 0 ${pxToRem(44)};
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
		font-weight: 500;
		font-size: ${pxToRem(15)};
		line-height: ${pxToRem(22)};
	`)}
`;

const LogoImg = styled.img`
	width: auto;
	height: ${pxToRem(19)};

	${getDesktopStyle(css`
		height: ${pxToRem(22)};
	`)}
`;

const FooterBox = styled.div`
	margin: 0 auto;

	${getDesktopStyle(css`
		width: ${pxToRem(INNER_ELEMENT_WIDTH)};
	`)}
`;

const FooterInfoMobileWrapper = styled.div`
	${showOnMobileStyle};
	margin: ${pxToRem(27)} 0 ${pxToRem(22)};
`;

const FooterInfoDesktopWrapper = styled.div`
	${showOnDesktopStyle};
	width: 100%;
	margin: ${pxToRem(24)} 0 ${pxToRem(23)};
`;

const FooterInfo = styled.p`
	margin-bottom: 3px;
`;

const FooterInfoText = styled.span`
	position: relative;
	padding-left: 10px;
	margin-left: 8px;

	&:first-of-type {
		padding-left: 0;
		margin-left: 0;
		&:after {
			display: none;
		}
	}

	&:after {
		position: absolute;
		left: 0;
		top: 4px;
		display: block;
		width: 1px;
		height: ${pxToRem(16)};
		background: ${WHITE};
		content: '';
	}
`;

const FooterLink = styled(Link)`
	text-decoration: underline;
`;
