import { INTERNAL_URLS, OUTER_URLS } from '../constants/urls';

export enum HeaderMenuType {
	Default = 'default',
	Story = 'story',
	Businesses = 'businesses',
	Contact = 'contact',
	None = 'none',
}

export enum HeaderStyleType {
	Transperant = 'Transperant',
	Background = 'background',
	White = 'white',
	Black = 'black',
}

export interface HeaderDataType {
	id: number;
	link: string;
	text: string;
	type: HeaderMenuType;
	isOuter: boolean;
	hasSubMenu: boolean;
	subMenu?: Pick<HeaderDataType, 'id' | 'link' | 'text'>[];
}

export const headerData = [
	{
		id: 0,
		link: INTERNAL_URLS.HOME,
		text: 'Story',
		type: HeaderMenuType.Story,
		isOuter: false,
		hasSubMenu: true,
		subMenu: [
			{
				id: 0,
				link: INTERNAL_URLS.HISTORY,
				text: 'Petdoc History',
			},
			// {
			// 	id: 1,
			// 	link: INTERNAL_URLS.VETERIANARIAN,
			// 	text: 'Petdoc의 수의사',
			// },
			// {
			// 	id: 2,
			// 	link: INTERNAL_URLS.VPETMILYLAB,
			// 	text: 'V+petmily Lab.',
			// },
		],
	},
	{
		id: 1,
		//link: INTERNAL_URLS.BUSINESSES,
		link: INTERNAL_URLS.FOURPAWS,
		text: 'Businesses',
		type: HeaderMenuType.Businesses,
		isOuter: false,
		hasSubMenu: true,
		subMenu: [
			// {
			// 	id: 0,
			// 	link: INTERNAL_URLS.PETDOCAPP,
			// 	text: 'Petdoc APP',
			// },
			{
				id: 1,
				link: INTERNAL_URLS.FOURPAWS,
				text: 'Four Paws',
			},
			{
				id: 2,
				link: INTERNAL_URLS.VPARTNERS,
				text: 'V+Partners',
			},
			// {
			// 	id: 3,
			// 	link: INTERNAL_URLS.VLAB,
			// 	text: 'V+Lab',
			// },
			{
				id: 4,
				link: INTERNAL_URLS.VAD,
				text: 'V+Ad',
			},
			{
				id: 5,
				link: INTERNAL_URLS.VCARE,
				text: 'V+Care',
			},
		],
	},
	{
		id: 2,
		link: INTERNAL_URLS.CONTACT,
		text: 'Contact',
		type: HeaderMenuType.Contact,
		isOuter: false,
		hasSubMenu: false,
	},
	{ id: 3, link: OUTER_URLS.RECRUIT, text: 'Recruit', isOuter: true, hasSubMenu: false },
];
